exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-business-exclusive-js": () => import("./../../../src/templates/businessExclusive.js" /* webpackChunkName: "component---src-templates-business-exclusive-js" */),
  "component---src-templates-business-public-js": () => import("./../../../src/templates/businessPublic.js" /* webpackChunkName: "component---src-templates-business-public-js" */),
  "component---src-templates-charge-points-js": () => import("./../../../src/templates/chargePoints.js" /* webpackChunkName: "component---src-templates-charge-points-js" */),
  "component---src-templates-chargespot-app-js": () => import("./../../../src/templates/chargespotApp.js" /* webpackChunkName: "component---src-templates-chargespot-app-js" */),
  "component---src-templates-chargespot-home-js": () => import("./../../../src/templates/chargespotHome.js" /* webpackChunkName: "component---src-templates-chargespot-home-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-diagonismos-smart-js": () => import("./../../../src/templates/diagonismosSmart.js" /* webpackChunkName: "component---src-templates-diagonismos-smart-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-service-page-js": () => import("./../../../src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/simplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */),
  "component---src-templates-steps-js": () => import("./../../../src/templates/steps.js" /* webpackChunkName: "component---src-templates-steps-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

